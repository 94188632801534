.box{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.logo-section-nav{
    display: flex;
}

.support-section{
    display: flex;
    gap: 50px;
    justify-content: space-evenly;
}

.support-link-button{
        
        border-radius: 60px;
        background-color: #EDEFF4;
        padding: 10px 15px;
        color: black;
}
